@tailwind base;
@tailwind components;
@tailwind utilities;

.active:active {
    transform: translate(1px, 1px);
}

.active:focus,
.inactive:focus {
    outline: 0;
}

.sq-login {
    background: #f5f5f4 !important;
}

.sq-login .RaLogin-avatar {
    display: none !important;
}

.sq-login .MuiPaper-root {
    padding: 24px;
}

.MuiDataGrid-columnHeaders {
background: rgb(245 245 244) !important;
}

.creditScoreTable {
    margin-top: -50px !important;
}

.ruleGroup-addGroup  {
    display: none !important;
}

/*
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  margin: 0 !important;
  border-radius: 0 !important;
} */
